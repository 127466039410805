@import url('../node_modules/slick-carousel/slick/slick-theme.css');
@import url('../node_modules/slick-carousel/slick/slick.css');

.App {
  text-align: center;
}

.p_brochure{
  background-color: #fff;
  padding: 30px 30px;
  box-shadow: 0px 0px 12px -4px #000;
  border-radius: 12px;
  margin-top: 30px;
}

.links{
  font-size: 13px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.btn-purple {
  color: #fff!important;
  background-color: #818dcb!important;
  border-color: #818dcb!important;
}

.checkbtn{
  color: #fff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #818dcb;
    background-color: #818dcb;
}
.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
